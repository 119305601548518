import React, {
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { useWindowSize } from 'sebn-library'

import { Context } from 'store'
import {
  setOrderNo,
  setEmail,
  setDate,
  setComplaints,
  setTermsAccepted,
  setErrors,
  setPartnerDataSharing
} from 'store/actions'
import { Terms, InputSelect, Input, InputCheckbox } from 'components'
import { project, validateEmail, validateOrderNo } from 'helpers'
import { ComplaintId, StepId } from 'interfaces'
import { orderCopy as copy, termsCopy } from 'assets/copy'
import useTranslate from 'hooks/useTranslate'

import { StyledLabel } from 'components/common/Input/Input.sc'
import {
  StyledContainer,
  StyledDetails,
  StyledTopics,
  StyledTerms
} from './Order.sc'

const Order: FC = (): ReactElement => {
  const { isMobile } = useWindowSize()
  const {
    state: {
      orderNo,
      email,
      date,
      complaints,
      termsAccepted,
      errors,
      partnerDataSharing
    },
    dispatch
  } = useContext(Context)
  const { translate: t, siteCode } = useTranslate()

  const handleComplaintChange = useCallback(
    (e: SyntheticEvent) => {
      const { name, checked } = e.target as HTMLInputElement

      const updatedComplaints = complaints.map((complaint) => {
        if (complaint.id === name) {
          return { ...complaint, selected: checked }
        }
        return complaint
      })

      dispatch(setComplaints(updatedComplaints))
    },
    [complaints, dispatch]
  )

  const validateAndSetOrderNo = useCallback(
    (e: SyntheticEvent) => {
      const { value } = e.target as HTMLInputElement
      const error = validateOrderNo(value, t)

      if (error) {
        dispatch(setErrors({ ...errors, orderNo: error }))
      }
      if (!error && errors.orderNo) {
        dispatch(setErrors({ ...errors, orderNo: '' }))
      }

      dispatch(setOrderNo(value))
    },
    [dispatch, errors, t]
  )

  const validateAndSetEmail = useCallback(
    (e: SyntheticEvent) => {
      const { value } = e.target as HTMLInputElement
      const error = validateEmail(value, t)

      if (error) {
        dispatch(setErrors({ ...errors, email: error }))
      }
      if (!error && errors.email) {
        dispatch(setErrors({ ...errors, email: '' }))
      }

      dispatch(setEmail(value))
    },
    [dispatch, errors, t]
  )

  const isDeliveryInstallationSelected = useMemo(
    () =>
      complaints.find((c) => c.id === ComplaintId.DELIVERY_OR_INSTALLATION)
        ?.selected,
    [complaints]
  )

  return (
    <StyledContainer>
      <StyledDetails>
        <Input
          id="orderNo"
          name="orderNo"
          label={`${t(copy.orderNo)}*`}
          placeholder={t(copy.orderNo)}
          value={orderNo}
          maxLength={30}
          onChange={validateAndSetOrderNo}
          error={orderNo && errors.orderNo}
        />

        <Input
          id="email"
          name="email"
          label={`${t(copy.email)}*`}
          placeholder={t(copy.email)}
          value={email}
          maxLength={50}
          onChange={validateAndSetEmail}
          error={email && errors.email}
        />

        <Input
          type="date"
          id="date"
          name="date"
          label={t(copy.date)}
          value={date}
          max={new Date().toISOString().split('T')[0]}
          onChange={(e: SyntheticEvent) =>
            dispatch(setDate((e.target as HTMLInputElement).value))
          }
        />
      </StyledDetails>

      <div>
        <StyledLabel>{t(copy.topic)}</StyledLabel>
        {isMobile ? (
          <InputSelect
            name="topic"
            options={complaints}
            onOptionsChange={handleComplaintChange}
            dataOmni={`${siteCode}:${project}:${StepId.ORDER}:Topics`}
          />
        ) : (
          <StyledTopics>
            {complaints.map((complaint) => (
              <InputCheckbox
                key={complaint.id}
                name={complaint.id}
                id={complaint.id}
                checked={complaint.selected}
                label={t(complaint.name)}
                dataOmni={`${siteCode}:${project}:${StepId.ORDER}:Topics:click:${complaint.name}`}
                onChange={handleComplaintChange}
              />
            ))}
          </StyledTopics>
        )}
      </div>

      <StyledTerms>
        <h6>{t(termsCopy.title)}</h6>
        <Terms
          id="termsAccepted"
          name="termsAccepted"
          checked={termsAccepted}
          text={t(termsCopy.lottery)}
          showInfo={false}
          onChange={(e) =>
            dispatch(setTermsAccepted((e.target as HTMLInputElement).checked))
          }
          dataOmni={`${siteCode}:${project}:${StepId.ORDER}:click:${t(termsCopy.lottery)}`}
        />
        {isDeliveryInstallationSelected && (
          <Terms
            id="partnerDataSharing"
            name="partnerDataSharing"
            checked={partnerDataSharing}
            text={t(termsCopy.shareData)}
            onChange={(e) =>
              dispatch(
                setPartnerDataSharing((e.target as HTMLInputElement).checked)
              )
            }
            dataOmni={`${siteCode}:${project}:delivery-or-installation:click:${t(termsCopy.shareData)}`}
          />
        )}
      </StyledTerms>
    </StyledContainer>
  )
}

export default Order
