import { styled } from 'styled-components'

type StyledCheckboxProps = {
  $width?: string
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  width: ${({ $width }) => $width || '12.5rem'};
`

export const StyledCheckboxLabel = styled.label`
  font-size: 0.875rem;
  line-height: 1.1875rem;
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #f7f7f7;
  border-radius: 0.3125rem;
  height: 3.3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 1rem;
  text-align: center;
`

export const StyledCheckboxInput = styled.input`
  display: none;

  &:checked + ${StyledCheckboxLabel} {
    border: 2px solid #2189ff;
  }
`
