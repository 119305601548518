import { Copy } from 'sebn-library/lib/esm/interfaces'

export const termsCopy: Copy = {
  title: {
    nl: 'Controleer de Algemene voorwaarden',
    be_fr: `Vérifier les termes et conditions`
  },
  required: {
    nl: 'Verplicht veld',
    be_fr: `Champs requis`
  },
  lottery: {
    nl: 'Wil je deelnemen aan de Samsung loterij (T&C-link)',
    be_fr: `Voulez-vous participer à la Samsung tombola? (T&C link)`
  },
  shareData: {
    nl: 'Mogen we je gegevens delen met onze (bezorg)partners om onze service te verbeteren',
    be_fr: `Pouvons-nous partager vos données avec nos partenaires (de livraison) afin d'améliorer notre service ?`
  }
}
