import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { tabletDown } = breakpoints

type StyledBallProps = {
  $checked: boolean
}

export const StyledTerms = styled.div`
  margin-bottom: 1rem;

  input {
    display: none;
  }

  label {
    cursor: pointer;
    font-size: 0.75rem;
    line-height: normal;
    display: inline-flex;
    align-items: center;
  }
`

export const StyledBall = styled.span<StyledBallProps>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: ${({ $checked }) =>
    $checked ? '1px solid transparent' : '1px solid #000'};
  border-radius: 50%;
  margin-right: 1rem;
  position: relative;
  background-color: ${({ $checked }) => ($checked ? '#2189ff' : 'transparent')};

  @media ${tabletDown} {
    flex-shrink: 0;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('https://images.samsung.com/is/content/samsung/assets/nl/cheil/complaint-form/icons/check.svg');
    background-size: contain;
    opacity: ${({ $checked }) => ($checked ? 1 : 0)};
  }
`

export const StyledInfo = styled.span`
  font-size: 0.625rem;
  line-height: normal;
  color: #d62e2e;
  display: block;
  margin: 1rem 2rem;
`
