import React, {
  FC,
  ReactElement,
  SyntheticEvent,
  useState,
  useMemo
} from 'react'
import { useClickOutside } from 'sebn-library'
import { Option } from 'interfaces'
import { microsite, project, optionsString } from 'helpers'
import useTranslate from 'hooks/useTranslate'

import {
  StyledContainer,
  StyledInputGroup,
  StyledInput,
  StyledSVG,
  StyledOptions,
  StyledOption,
  StyledOptionInput,
  StyledOptionLabel,
  StyledOptionTick
} from './InputSelect.sc'

type InputSelectProps = {
  name: string
  options: Option[]
  onOptionsChange: (e: SyntheticEvent) => void
  dataOmni: string
}

const InputSelect: FC<InputSelectProps> = ({
  name,
  options,
  onOptionsChange,
  dataOmni
}): ReactElement => {
  const { translate: t, siteCode } = useTranslate()

  const [showOptions, setShowOptions] = useState<boolean>(false)
  const ref: any = useClickOutside(() => setShowOptions(false))

  const inputValue = useMemo(() => optionsString(options, t), [options, t])

  return (
    <StyledContainer ref={ref}>
      <StyledInputGroup htmlFor={name}>
        <StyledInput
          type="text"
          name={name}
          id={name}
          placeholder="Label"
          readOnly
          value={inputValue}
          onClick={() => setShowOptions(!showOptions)}
          data-omni-type={microsite}
          data-omni={`${siteCode}:${project}:options:click:${showOptions ? 'hide' : 'show'}`}
        />
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          $isOpen={showOptions}
        >
          <path
            d="M19.9297 8.08496L20.8197 8.96196L11.9997 17.914L3.17969 8.96196L4.06969 8.08496L11.9997 16.133L19.9297 8.08496Z"
            fill="black"
          />
        </StyledSVG>
      </StyledInputGroup>

      {showOptions && (
        <StyledOptions>
          {options.map((option) => (
            <StyledOption key={option.id}>
              <StyledOptionInput
                type="checkbox"
                name={option.id}
                id={option.id}
                checked={option.selected}
                onChange={onOptionsChange}
                data-omni-type={microsite}
                data-omni={`${dataOmni}:click:${t(option.name)}`}
              />
              <StyledOptionLabel htmlFor={option.id}>
                <StyledOptionTick $checked={option.selected}>
                  &nbsp;
                </StyledOptionTick>
                <span>{t(option.name)}</span>
              </StyledOptionLabel>
            </StyledOption>
          ))}
        </StyledOptions>
      )}
    </StyledContainer>
  )
}

export default InputSelect
