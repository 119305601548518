import { styled } from 'styled-components'

export const StyledComplaintOverview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const StyledRow = styled.div`
  p {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    margin-top: 0.5rem;
  }
`
