import { Copy } from 'sebn-library/lib/esm/interfaces'

export const navigationCopy: Copy = {
  prev: {
    nl: 'Terug',
    be_fr: 'Dos'
  },
  next: {
    nl: 'Volgende',
    be_fr: 'Suivante'
  },
  submit: {
    nl: 'Indienen',
    be_fr: 'Soumettre'
  }
}
