import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { tabletDown, phoneDown } = breakpoints

export const StyledCarriers = styled.ul`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
  cursor: pointer;
  flex-wrap: wrap;

  @media ${tabletDown} {
    gap: 1rem;
  }

  @media ${phoneDown} {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }
`

export const StyledCarrier = styled.li`
  @media ${phoneDown} {
    flex-basis: calc(50% - 0.5rem);
  }

  input {
    display: none;
  }

  label {
    display: block;
    height: 100%;
    border: 2px solid transparent;
    border-radius: 0.3125rem;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease;

    span {
      background: #f7f7f7;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      color: #555;
      font-size: 0.8rem;
    }

    @media ${phoneDown} {
      width: 100%;
    }
  }

  img {
    @media ${phoneDown} {
      width: 100%;
    }
  }

  input:checked + label {
    border: 2px solid #2189ff;
  }
`
