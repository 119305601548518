import { Copy } from 'sebn-library/lib/esm/interfaces'

export const confirmationCopy: Copy = {
  thankyou: {
    nl: 'Bedankt!',
    be_fr: 'Merci!'
  },
  text: {
    nl: 'Uw antwoorden zijn succesvol verzonden!',
    be_fr: `Vos réponses ont été soumises avec succès!`
  },
  goBack: {
    nl: 'Ga terug naar de winkel',
    be_fr: `Retourner à la boutique`
  },
  title: {
    nl: 'Bevestiging',
    be_fr: 'Confirmation'
  },
  ctaLink: {
    nl: 'https://www.samsung.com/nl/',
    be: 'https://www.samsung.com/be/',
    be_fr: 'https://www.samsung.com/be_fr/'
  }
}
