import React, { FC, ReactElement, useContext } from 'react'

import { Context } from 'store'
import { setActiveStep } from 'store/actions'
import { OrderOverview, ComplaintOverview } from 'components'
import { ComplaintId, StepId } from 'interfaces'
import { microsite, project } from 'helpers'
import useTranslate from 'hooks/useTranslate'

import {
  StyledOverview,
  StyledCard,
  StyledHeader,
  StyledTitle
} from './Overview.sc'

const contentCdn = process.env.REACT_APP_CONTENT_CDN

const Overview: FC = (): ReactElement => {
  const {
    state: { steps, activeStep },
    dispatch
  } = useContext(Context)
  const { siteCode } = useTranslate()

  return (
    <StyledOverview>
      {steps
        .filter((step) => step.id !== StepId.SUBMIT)
        .map((step, index) => (
          <StyledCard key={step.id}>
            <StyledHeader>
              <StyledTitle>
                <span>{index + 1}</span>
                {step.navTitle}
              </StyledTitle>

              <button
                onClick={() => dispatch(setActiveStep(step))}
                data-omni-type={microsite}
                data-omni={`${siteCode}:${project}:step-${activeStep.id}:click:edit:${step.navTitle}`}
              >
                <img src={`${contentCdn}/icons/edit.svg`} alt="Edit" />
              </button>
            </StyledHeader>

            {step.id === StepId.ORDER ? (
              <OrderOverview />
            ) : (
              <ComplaintOverview complaintId={step.id as ComplaintId} />
            )}
          </StyledCard>
        ))}
    </StyledOverview>
  )
}

export default Overview
