import { createContext, useReducer, FC, Dispatch, ReactElement } from 'react'
import { ActionType, StateType } from 'interfaces'

import reducer from './reducer'
import { complaints, steps } from 'data'

const initialState: StateType = {
  language: 'nl',
  isLoading: true,
  steps,
  activeStep: steps[0],
  orderNo: '',
  email: '',
  date: new Date().toISOString().split('T')[0],
  complaints,
  termsAccepted: false,
  partnerDataSharing: false,
  submitFeedback: false,
  errors: {
    orderNo: '',
    email: ''
  }
}

const Context = createContext<{
  state: StateType
  dispatch: Dispatch<ActionType>
}>({
  state: initialState,
  dispatch: () => null
})

interface ProviderProps {
  children: ReactElement
}

const Provider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export { Context, Provider }
