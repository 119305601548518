import React, { FC, ReactElement, useCallback, useContext } from 'react'
import { useWindowSize } from 'sebn-library'

import { Context } from 'store'
import { Carrier, Complaint } from 'interfaces'
import { setComplaints } from 'store/actions'
import { complaintCopy as copy } from 'assets/copy'
import { microsite, project } from 'helpers'
import useTranslate from 'hooks/useTranslate'

import { StyledCarriers, StyledCarrier } from './Carriers.sc'

type CarriersProps = {
  activeComplaint: Complaint
}

const imageCdn = process.env.REACT_APP_IMAGE_CDN

const Carriers: FC<CarriersProps> = ({ activeComplaint }): ReactElement => {
  const { width } = useWindowSize()
  const {
    state: { complaints },
    dispatch
  } = useContext(Context)
  const { translate: t, siteCode } = useTranslate()

  const handleChange = useCallback(
    (carrier: Carrier) => {
      const updatedComplaints = complaints.map((complaint) => {
        if (complaint.id === activeComplaint.id) {
          return { ...complaint, selectedCarrier: carrier }
        }

        return complaint
      })

      dispatch(setComplaints(updatedComplaints))
    },
    [activeComplaint, complaints, dispatch]
  )

  if (!activeComplaint?.carriers) return <></>

  return (
    <div>
      <h5>
        {t(copy.carrier)} <sup>*</sup>
      </h5>
      <StyledCarriers>
        {activeComplaint.carriers.map((carrier) => (
          <StyledCarrier key={carrier.id}>
            <input
              type="radio"
              name={`${activeComplaint.id}-carrier`}
              id={carrier.id}
              checked={activeComplaint.selectedCarrier?.id === carrier.id}
              onChange={() => handleChange(carrier)}
              data-omni-type={microsite}
              data-omni={`${siteCode}:${project}:${activeComplaint.navTitle}:carriers:click:${carrier.name}`}
            />
            <label htmlFor={carrier.id}>
              {carrier.id === 'other' ? (
                <span>{carrier.name}</span>
              ) : (
                <img
                  src={`${imageCdn}/${width < 500 ? carrier.imageUrlMobile : carrier.imageUrl}`}
                  alt={carrier.name}
                />
              )}
            </label>
          </StyledCarrier>
        ))}
      </StyledCarriers>
    </div>
  )
}

export default Carriers
