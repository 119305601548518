import React, { FC, ReactElement, useContext, useMemo } from 'react'

import { Context } from 'store'
import { orderCopy as copy, confirmationCopy } from 'assets/copy'
import { StepId } from 'interfaces'
import useTranslate from 'hooks/useTranslate'

import { StyledHeader } from './Header.sc'

const Header: FC = (): ReactElement => {
  const {
    state: { activeStep, submitFeedback }
  } = useContext(Context)
  const { translate: t } = useTranslate()

  const title = useMemo(() => {
    if (submitFeedback) return t(confirmationCopy.title)
    if (activeStep.id === StepId.ORDER) return t(copy.title)

    return activeStep.navTitle
  }, [activeStep, submitFeedback, t])

  return (
    <StyledHeader>
      <h2>{title}</h2>
      <p>{t(copy.description)}</p>
    </StyledHeader>
  )
}

export default Header
