import { FC, ReactElement, useContext, useMemo } from 'react'

import { Context } from 'store'
import { Carriers, Reasons, Explanation } from 'components'
import { ComplaintId } from 'interfaces'
import useTranslate from 'hooks/useTranslate'

import { StyledComplaint, StyledWrapper } from './Complaint.sc'

const Complaint: FC = (): ReactElement => {
  const { OTHER } = ComplaintId
  const {
    state: { activeStep, complaints }
  } = useContext(Context)
  const { translate: t } = useTranslate()

  const activeComplaint = useMemo(
    () => complaints.find((c) => c.id === activeStep.id),
    [activeStep, complaints]
  )

  if (!activeComplaint) return <></>

  return (
    <StyledComplaint>
      <h3>{t(activeComplaint.name)}</h3>

      {activeComplaint.carriers && (
        <Carriers activeComplaint={activeComplaint} />
      )}

      <StyledWrapper>
        {activeStep.id !== OTHER && (
          <div>
            <Reasons activeComplaint={activeComplaint} />
          </div>
        )}
        <Explanation activeComplaint={activeComplaint} />
      </StyledWrapper>
    </StyledComplaint>
  )
}

export default Complaint
