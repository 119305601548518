import React, { FC, ReactElement, SyntheticEvent } from 'react'

import { StyledLabel, StyledInput, StyledError } from './Input.sc'

type InputProps = {
  id: string
  name: string
  value: string
  label: string
  placeholder?: string
  type?: string
  maxLength?: number
  max?: string
  error?: string
  onChange: (e: SyntheticEvent) => void
}

const Input: FC<InputProps> = ({
  id,
  name,
  value,
  label,
  placeholder = '',
  type = 'text',
  maxLength,
  max,
  error,
  onChange
}): ReactElement => {
  return (
    <div>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        max={max}
        onChange={onChange}
      />

      {error && <StyledError>{error}</StyledError>}
    </div>
  )
}

export default Input
