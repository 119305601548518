import { styled } from 'styled-components'
import { BaseInputStyles } from 'components/common/Input/Input.sc'

export const StyledExplanation = styled.div`
  flex: 1;
`

export const StyledTextarea = styled.textarea`
  ${BaseInputStyles}
  height: 12.5rem;
`
