import React, { FC, ReactElement } from 'react'
import { ButtonVariant } from 'interfaces'
import { microsite } from 'helpers'

import { StyledButton } from './Button.sc'

type ButtonProps = {
  children: ReactElement | string
  variant: ButtonVariant
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  dataOmni: string
}

const Button: FC<ButtonProps> = ({
  children,
  variant,
  disabled,
  onClick,
  dataOmni
}): ReactElement => {
  return (
    <StyledButton
      $variant={variant}
      disabled={disabled}
      onClick={onClick}
      data-omni-type={microsite}
      data-omni={dataOmni}
    >
      {children}
    </StyledButton>
  )
}

export default Button
