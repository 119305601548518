import { Copy } from 'sebn-library/lib/esm/interfaces'

export const orderCopy: Copy = {
  title: {
    nl: 'Klachten formulier',
    be_fr: `Formulaire de réclamation`
  },
  description: {
    nl: `Het spijt ons dat je ervaring met Samsung niet naar wens is. Vul het onderstaande formulier in zodat wij je klacht kunnen onderzoeken, oplossen en onze service kunnen verbeteren.`,
    be_fr: `Nous sommes désolés que votre expérience avec Samsung ne vous satisfasse pas. Veuillez remplir le formulaire ci-dessous afin que nous puissions enquêter, résoudre votre plainte et améliorer notre service.`
  },
  orderNo: {
    nl: 'Order nummer',
    be_fr: `Numéro de commande`
  },
  email: {
    nl: 'E-mail adres',
    be_fr: ''
  },
  date: {
    nl: 'Datum',
    be_fr: 'Date'
  },
  orderDate: {
    nl: 'Order Datum*',
    be_fr: 'Date de Commande'
  },
  topic: {
    nl: 'Onderwerp*',
    be_fr: 'Sujet'
  },
  invalidOrderNo: {
    nl: 'Bestelnummer bevat alleen cijfers, letters en -',
    be_fr: `Le numéro de commande contient uniquement des chiffres, des lettres et -`
  },
  invalidEmail: {
    nl: 'Gelieve een geldig e-mailadres in te vullen',
    be_fr: `Veuillez entrer un email valide`
  }
}
