import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { tabletDown } = breakpoints

const CARD_GAP = '1.5rem'

export const StyledOverview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${CARD_GAP};
`

export const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  flex-basis: calc(50% - (${CARD_GAP} / 2));

  @media ${tabletDown} {
    flex-basis: 100%;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`

export const StyledTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0;

  span {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.875rem;
    line-height: normal;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
