import React, { FC, ReactElement, useContext, useMemo } from 'react'
import moment from 'moment'
import { Context } from 'store'
import { orderCopy as copy, complaintCopy } from 'assets/copy'

import { StyledOrderOverview, StyledData } from './OrderOverview.sc'
import useTranslate from 'hooks/useTranslate'

const OrderOverview: FC = (): ReactElement => {
  const {
    state: { orderNo, email, date, complaints }
  } = useContext(Context)
  const { translate: t } = useTranslate()

  const selectedComplaints = useMemo(
    () => complaints.filter((c) => c.selected),
    [complaints]
  )

  return (
    <StyledOrderOverview>
      <StyledData>
        <h4>{t(copy.orderNo)}:</h4>
        <p>{orderNo}</p>
      </StyledData>
      <StyledData>
        <h4>{t(copy.email)}:</h4>
        <p>{email}</p>
      </StyledData>
      <StyledData>
        <h4>{t(copy.date)}</h4>
        <p>{moment(date).format('DD/MM/YYYY')}</p>
      </StyledData>
      <StyledData>
        <h4>{t(complaintCopy.reason)}</h4>
        <div>
          {selectedComplaints.map((complaint) => (
            <p key={complaint.id}>{complaint.navTitle}</p>
          ))}
        </div>
      </StyledData>
    </StyledOrderOverview>
  )
}

export default OrderOverview
