import axios from 'axios'
import { StateType } from 'interfaces'
import { isDevelopment } from 'helpers'

const API_URL = isDevelopment
  ? 'https://vwwbwjsjq1.execute-api.eu-central-1.amazonaws.com/dev'
  : 'https://il9u6v37dj.execute-api.eu-central-1.amazonaws.com/prod'

export const createFeedback = async (state: StateType) => {
  try {
    const payload = {
      orderNo: state.orderNo,
      email: state.email,
      date: new Date(state.date),
      termsAccepted: state.termsAccepted,
      partnerDataSharing: state.partnerDataSharing,
      complaints: state.complaints
        .filter((c) => c.selected)
        .map((c) => {
          return {
            id: c.id,
            name: c.name.nl,
            description: c.description,
            carrier: c.selectedCarrier?.name,
            reasons: c.reasons
              .filter((r) => r.selected)
              .map((r) => {
                return { id: r.id, name: r.name.nl }
              })
          }
        })
    }

    const res = await axios.post(`${API_URL}/save`, payload)

    return res.data
  } catch (err) {
    throw new Error(`Error while submitting feedback - ${err}`)
  }
}
