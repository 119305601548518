import { styled, css } from 'styled-components'
import { ButtonVariant } from 'interfaces'

type StyledButtonProps = {
  $variant: ButtonVariant
  $large?: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  border-radius: 1.25rem;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.33, 0, 0.3, 1);

  &:disabled {
    cursor: default;
  }

  ${({ $variant }) => {
    if ($variant === 'black') {
      return css`
        background-color: #000;
        color: #fff;

        &:hover,
        &:focus {
          background-color: #555;
          color: #eee;
        }

        &:disabled {
          background-color: #ddd;
          color: #aaa;
        }
      `
    }

    if ($variant === 'emphasis') {
      return css`
        background-color: #2189ff;
        color: #fff;

        &:hover,
        &:focus {
          background-color: #006bea;
          color: #eee;
        }

        &:disabled {
          background-color: #ddd;
          color: #aaa;
        }
      `
    }

    if ($variant === 'outlined') {
      return css`
        border: 1px solid #000;

        &:hover,
        &:focus {
          background-color: rgba(0, 0, 0, 0.05);
          color: #555;
          border-color: #555;
        }

        &:disabled {
          background-color: transparent;
          color: #ddd;
          border-color: #ddd;
        }
      `
    }

    if ($variant === 'underline') {
      return css`
        border-radius: 0;
        position: relative;
        padding: 0;
        width: auto;
        margin: 2px 0;

        &::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #000;
        }

        &:disabled {
          color: #aaa;
        }

        &:disabled::after {
          background-color: #aaa;
        }

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          &::after {
            animation: cta-underline-animation 0.2s cubic-bezier(0.4, 0, 0.2, 1)
              0.1s both;
          }
        }

        @keyframes cta-underline-animation {
          from {
            transform: scaleX(0);
          }
          to {
            transform: scaleX(1);
          }
        }
      `
    }
  }}
`
