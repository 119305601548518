import { styled } from 'styled-components'

const DATA_GAP = '1rem'

export const StyledOrderOverview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${DATA_GAP};
`

export const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-basis: calc(50% - (${DATA_GAP} / 2));

  p {
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }
`
