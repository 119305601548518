import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo
} from 'react'

import { Context } from 'store'
import { setActiveStep, submitFeedback } from 'store/actions'
import { Button } from 'components'
import { ComplaintId, StepId } from 'interfaces'
import { project } from 'helpers'
import { navigationCopy as copy } from 'assets/copy'
import useTranslate from 'hooks/useTranslate'

import { StyledNavigation } from './Navigation.sc'

const Navigation: FC = (): ReactElement => {
  const { state, dispatch } = useContext(Context)
  const {
    complaints,
    steps,
    activeStep,
    orderNo,
    email,
    date,
    partnerDataSharing,
    errors
  } = state
  const { translate: t, siteCode } = useTranslate()

  const handleSubmit = useCallback(async () => {
    dispatch(await submitFeedback(state))
  }, [dispatch, state])

  const handlePrevBtnClick = useCallback(() => {
    const activeStepIndex = steps.findIndex((step) => step.id === activeStep.id)
    if (activeStepIndex === 0) return

    dispatch(setActiveStep(steps[activeStepIndex - 1]))
  }, [activeStep, dispatch, steps])

  const handleNextBtnClick = useCallback(() => {
    if (activeStep.id === StepId.SUBMIT) {
      handleSubmit()
      return
    }

    const activeStepIndex = steps.findIndex((step) => step.id === activeStep.id)
    dispatch(setActiveStep(steps[activeStepIndex + 1]))
  }, [activeStep, dispatch, handleSubmit, steps])

  const isDeliveryInstallationSelected = useMemo(
    () =>
      complaints.find((c) => c.id === ComplaintId.DELIVERY_OR_INSTALLATION)
        ?.selected,
    [complaints]
  )

  const isPrevBtnDisabled = useMemo(() => {
    const activeStepIndex = steps.findIndex((step) => step.id === activeStep.id)
    if (activeStepIndex === 0) return true
  }, [activeStep, steps])

  const isNextBtnDisabled = useMemo(() => {
    if (activeStep.id === StepId.SUBMIT || activeStep.id === ComplaintId.OTHER)
      return false
    if (activeStep.id === StepId.ORDER) {
      const selectedComplaints = complaints.some((c) => c.selected)

      if (errors.orderNo || errors.email) return true
      if (!(orderNo && email && date && selectedComplaints)) return true
      if (isDeliveryInstallationSelected && !partnerDataSharing) return true

      return false
    }

    const complaint = complaints.find((c) => c.id === activeStep.id)
    const selectedReasons = complaint?.reasons.some((r) => r.selected)

    if (
      activeStep.id === ComplaintId.DELIVERY_OR_INSTALLATION &&
      (!selectedReasons || !partnerDataSharing || !complaint?.selectedCarrier)
    )
      return true

    if (!selectedReasons) return true
  }, [
    activeStep,
    complaints,
    partnerDataSharing,
    errors.orderNo,
    errors.email,
    orderNo,
    email,
    date,
    isDeliveryInstallationSelected
  ])

  const nextBtnCopy = useMemo(
    () => (activeStep.id === StepId.SUBMIT ? t(copy.submit) : t(copy.next)),
    [activeStep.id, t]
  )

  return (
    <StyledNavigation>
      <Button
        variant="underline"
        disabled={isPrevBtnDisabled}
        onClick={handlePrevBtnClick}
        dataOmni={`${siteCode}:${project}:navigation:click:${t(copy.prev)}`}
      >
        {t(copy.prev)}
      </Button>
      <Button
        variant="emphasis"
        disabled={isNextBtnDisabled}
        onClick={handleNextBtnClick}
        dataOmni={`${siteCode}:${project}:navigation:click:${nextBtnCopy}`}
      >
        {nextBtnCopy}
      </Button>
    </StyledNavigation>
  )
}

export default Navigation
