import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'
import { StepperState } from 'interfaces'

const { tabletDown, phoneDown } = breakpoints

const CIRCLE_WIDTH = 24
const DIVIDER_MARGIN = 16
const DIVIDER_MARGIN_SM = 5

type StyledItemProps = {
  $state: StepperState
}

export const StyledList = styled.ol`
  margin: 3.375rem auto;
  counter-reset: list-items;
  display: flex;
  justify-content: center;

  @media ${tabletDown} {
    margin: 2rem auto;
  }
`

export const StyledItem = styled.li<StyledItemProps>`
  flex-basis: 100%;
  max-width: 11.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  span {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    font-weight: ${({ $state }) => {
      if ($state === StepperState.ACTIVE) return 700
      return 400
    }};
    max-width: 5rem;

    @media ${tabletDown} {
      display: ${({ $state }) => {
        if ($state === StepperState.ACTIVE) return 'block'
        return 'none'
      }};
    }
  }

  &::before {
    content: counter(list-items);
    counter-increment: list-items;
    display: block;
    width: ${CIRCLE_WIDTH}px;
    height: ${CIRCLE_WIDTH}px;
    border-radius: 1.25rem;
    font-size: 0.875rem;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.75rem;
    background-color: ${({ $state }) => {
      if ($state === StepperState.ACTIVE) return '#2189ff'
      if ($state === StepperState.DONE) return '#000'
      return '#8f8f8f'
    }};
    cursor: ${({ $state }) => {
      if ($state === StepperState.DONE) return 'pointer'
      return 'default'
    }};
  }

  &:not(:first-of-type)::after {
    content: '';
    display: block;
    width: calc(100% - ${CIRCLE_WIDTH}px - (2 * ${DIVIDER_MARGIN}px));
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: calc(-50% + ${CIRCLE_WIDTH / 2}px + ${DIVIDER_MARGIN}px);
    top: ${CIRCLE_WIDTH / 2}px;

    @media ${phoneDown} {
      width: calc(100% - ${CIRCLE_WIDTH}px - (2 * ${DIVIDER_MARGIN_SM}px));
      left: calc(-50% + ${CIRCLE_WIDTH / 2}px + ${DIVIDER_MARGIN_SM}px);
    }
  }
`
