import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { Provider } from 'store/index'

import App from './App'

import './assets/scss/main.scss'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ecfca313cf76f6380812dc87db866223@o268697.ingest.us.sentry.io/4507064250859520',
    defaultIntegrations: false
  })
}

const router = createBrowserRouter([
  {
    path: '/:lang?',
    element: <App />
  },
  {
    path: '*',
    element: <App />
  }
])

const container = document.getElementById('root') as Element
const root = createRoot(container)

root.render(
  <StrictMode>
    <Provider>
      <RouterProvider router={router} />
    </Provider>
  </StrictMode>
)
