import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { tabletDown } = breakpoints

export const StyledConfirmation = styled.div`
  margin-top: 11.25rem;
  text-align: center;

  @media ${tabletDown} {
    margin-top: 8rem;
  }

  button {
    margin: 3.375rem auto 9.375rem;

    @media ${tabletDown} {
      margin: 2rem auto;
    }
  }
`

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;

    @media ${tabletDown} {
      font-size: 1.125rem;
      line-height: normal;
    }
  }

  svg {
    @media ${tabletDown} {
      width: 4rem;
      height: 4rem;
    }
  }
`
