import { orderCopy } from 'assets/copy'
import { TranslateHook } from 'interfaces'

const orderNoRegex = new RegExp(/^[a-zA-Z0-9-]+$/)

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export const validateOrderNo = (orderNo: string, t: TranslateHook) => {
  const isValid = orderNoRegex.test(orderNo)
  return isValid ? '' : t(orderCopy.invalidOrderNo)
}

export const validateEmail = (email: string, t: TranslateHook) => {
  const isValid = emailRegex.test(email)
  return isValid ? '' : t(orderCopy.invalidEmail)
}
