import { styled } from 'styled-components'

export const StyledReasons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  width: 26.875rem;
`
