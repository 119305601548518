export const breakpoints = {
  phoneMiniDown: 'only screen and (max-width: 374px)',
  phoneMiniUp: 'only screen and (min-width: 375px)',
  phoneDown: 'only screen and (max-width: 500px)',
  phoneUp: 'only screen and (min-width: 501px)',
  tabletDown: 'only screen and (max-width: 767px)',
  tabletUp: 'only screen and (min-width: 768px)',
  tabletLandscapeDown: 'only screen and (max-width: 900px)',
  tabletLandscapeUp: 'only screen and (min-width: 901px)',
  desktopDown: 'only screen and (max-width: 1200px)',
  desktopUp: 'only screen and (min-width: 1201px)',
  largeDesktopDown: 'only screen and (max-width: 1439px)',
  largeDesktopUp: 'only screen and (min-width: 1440px)',
  phoneMiniToPhone: `only screen and (min-width: 376px) and (max-width: 500px)`,
  phoneToTablet: `only screen and (min-width: 501px) and (max-width: 766px)`,
  phoneToDesktop: `only screen and (min-width: 501px) and (max-width: 1200px)`,
  tabletToDesktop: `only screen and (min-width: 767px) and (max-width: 1200px)`,
  tabletToLandscape: `only screen and (min-width: 767px) and (max-width: 900px)`,
  desktopToLargeDesktop: `only screen and (min-width: 1201px) and (max-width: 1439px)`,
  desktopToBigDesktop: `only screen and (min-width: 1201px) and (max-width: 1652px)`
}
