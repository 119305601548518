import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { desktopDown, tabletDown } = breakpoints

export const StyledComplaint = styled.div`
  h3 {
    margin-bottom: 2.5rem;

    @media ${tabletDown} {
      margin-bottom: 1.5rem;
    }
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  @media ${desktopDown} {
    gap: 1rem;
  }

  @media ${tabletDown} {
    flex-direction: column;
  }

  & > div {
    min-width: 40%;
  }
`
