import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect
} from 'react'

import { Context } from 'store'
import { setSteps, setActiveStep } from 'store/actions'
import { Step, StepperState } from 'interfaces'
import { steps as defaultSteps } from 'data'
import { microsite, project } from 'helpers'

import { StyledList, StyledItem } from './Stepper.sc'
import useTranslate from 'hooks/useTranslate'

const Stepper: FC = (): ReactElement => {
  const {
    state: { activeStep, steps, complaints },
    dispatch
  } = useContext(Context)
  const { siteCode } = useTranslate()

  const updateSteps = useCallback(() => {
    const selectedComplaints = complaints.filter((c) => c.selected)

    if (!selectedComplaints.length) {
      dispatch(setSteps(defaultSteps))
      return
    }

    const updatedSteps = [...defaultSteps]
    updatedSteps.splice(
      1,
      1,
      ...selectedComplaints.map((complaint) => ({
        id: complaint.id,
        navTitle: complaint.navTitle
      }))
    )

    dispatch(setSteps(updatedSteps))
  }, [complaints, dispatch])

  const stepState = useCallback(
    (step: Step) => {
      const currentStepIndex = steps.findIndex((s) => s.id === step.id)
      const activeStepIndex = steps.findIndex((s) => s.id === activeStep.id)

      if (step.id === activeStep.id) return StepperState.ACTIVE
      if (currentStepIndex < activeStepIndex) return StepperState.DONE

      return StepperState.DEFAULT
    },
    [activeStep, steps]
  )

  useEffect(() => {
    updateSteps()
  }, [updateSteps])

  return (
    <StyledList>
      {steps.map((step) => (
        <StyledItem
          key={step.id}
          $state={stepState(step)}
          onClick={() =>
            stepState(step) === StepperState.DONE &&
            dispatch(setActiveStep(step))
          }
          data-omni-type={microsite}
          data-omni={`${siteCode}:${project}:stepper:click:${step.navTitle}`}
        >
          <span dangerouslySetInnerHTML={{ __html: step.navTitle }} />
        </StyledItem>
      ))}
    </StyledList>
  )
}

export default Stepper
