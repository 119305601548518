import React, { FC, ReactElement, SyntheticEvent } from 'react'
import { microsite } from 'helpers'
import {
  StyledCheckbox,
  StyledCheckboxInput,
  StyledCheckboxLabel
} from './InputCheckbox.sc'

type InputCheckboxProps = {
  name: string
  id: string
  checked: boolean
  label: string
  dataOmni: string
  type?: string
  width?: string
  onChange: (e: SyntheticEvent) => void
}

const InputCheckbox: FC<InputCheckboxProps> = ({
  id,
  name,
  checked,
  label,
  dataOmni,
  type = 'checkbox',
  width,
  onChange
}): ReactElement => {
  return (
    <StyledCheckbox $width={width}>
      <StyledCheckboxInput
        type={type}
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
        data-omni-type={microsite}
        data-omni={dataOmni}
      />
      <StyledCheckboxLabel htmlFor={id}>{label}</StyledCheckboxLabel>
    </StyledCheckbox>
  )
}

export default InputCheckbox
