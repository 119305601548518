import { Complaint, ComplaintId } from 'interfaces'

const {
  WEBSITE,
  PRODUCT_INFORMATION,
  ONLINE_PURCHASING,
  DELIVERY_OR_INSTALLATION,
  OTHER
} = ComplaintId

export const complaints: Complaint[] = [
  {
    id: WEBSITE,
    navTitle: 'Website',
    name: {
      nl: 'Website Design & Navigation',
      be_fr: `Conception du site web et navigation`
    },
    description: '',
    selected: false,
    reasons: [
      {
        id: 'slow-loading-speed',
        name: { nl: 'Laadtijden', be_fr: 'Vitesse de chargement lente' },
        selected: false
      },
      {
        id: 'search-functionality',
        name: {
          nl: 'Zoeken naar producten',
          be_fr: 'Fonctionnalité de recherche'
        },
        selected: false
      },
      {
        id: 'navigation',
        name: { nl: 'Efficiënte navigatie', be_fr: 'Navigation' },
        selected: false
      },
      {
        id: 'layout',
        name: { nl: 'Overzichtelijke lay-out', be_fr: 'Disposition' },
        selected: false
      },
      { id: 'other', name: { nl: 'Anders', be_fr: 'Autre' }, selected: false }
    ]
  },
  {
    id: PRODUCT_INFORMATION,
    navTitle: 'Product information',
    name: {
      nl: 'Product Information',
      be_fr: `Informations sur le produit`
    },
    description: '',
    selected: false,
    reasons: [
      {
        id: 'product-search',
        name: { nl: 'Zoeken naar producten', be_fr: 'Recherche de produits' },
        selected: false
      },
      {
        id: 'comparing-products',
        name: {
          nl: 'Vergelijken van producten',
          be_fr: 'Comparaison des produits'
        },
        selected: false
      },
      {
        id: 'product-info',
        name: {
          nl: 'Product informatie',
          be_fr: 'Informations sur les produits'
        },
        selected: false
      },
      {
        id: 'easy-to-understand',
        name: { nl: 'Makkelijk te begrijpen', be_fr: 'Facile à comprendre' },
        selected: false
      },
      {
        id: 'other',
        name: { nl: 'Anders', be_fr: `Autre` },
        selected: false
      }
    ]
  },
  {
    id: ONLINE_PURCHASING,
    navTitle: 'Online purchasing',
    name: {
      nl: 'Online Purchasing',
      be_fr: `Achats en ligne`
    },
    description: '',
    selected: false,
    reasons: [
      {
        id: 'promotion',
        name: { nl: 'Promoties', be_fr: 'Promotion' },
        selected: false
      },
      {
        id: 'payment',
        name: { nl: 'Betalen', be_fr: 'Paiement' },
        selected: false
      },
      {
        id: 'address-fields',
        name: { nl: 'Adresvelden', be_fr: `Champs d'adresse` },
        selected: false
      },
      {
        id: 'check-out',
        name: { nl: 'Check-out', be_fr: 'Check-out' },
        selected: false
      },
      {
        id: 'delivery-options',
        name: { nl: 'Leveropties', be_fr: 'Options de livraison' },
        selected: false
      },
      {
        id: 'other',
        name: { nl: 'Anders', be_fr: `Autre` },
        selected: false
      }
    ]
  },
  {
    id: DELIVERY_OR_INSTALLATION,
    navTitle: `Delivery/ installation`,
    name: {
      nl: 'Delivery/Installation',
      be_fr: `Livraison/Installation`
    },
    description: '',
    selected: false,
    reasons: [
      {
        id: 'missed-delivery-date',
        name: {
          nl: 'Gemist tijdsvak/ leverdag',
          be_fr: `Créneau horaire/jour de livraison manqué(e)`
        },
        selected: false
      },
      {
        id: 'installation-not-as-expected',
        name: {
          nl: 'Installatie niet als verwacht',
          be_fr: `L'installation ne correspond pas aux attentes`
        },
        selected: false
      },
      {
        id: 'installation-not-complete',
        name: {
          nl: 'Installatie incompleet',
          be_fr: 'nstallation incomplète'
        },
        selected: false
      },
      {
        id: 'package-damage',
        name: { nl: 'Item is beschadigd', be_fr: `L'article est endommagé` },
        selected: false
      },
      {
        id: 'driver-language-barrier',
        name: {
          nl: 'Medewerker sprak niet mijn taal',
          be_fr: `L'employé ne parlait pas ma langue`
        },
        selected: false
      },
      {
        id: 'driver-unprofessional',
        name: {
          nl: `Medewerker was onbeleefd/ onproffesioneel`,
          be_fr: `Le membre du personnel a été impoli/ non professionnel`
        },
        selected: false
      },
      {
        id: 'repair-or-exchange',
        name: { nl: 'Reparatie/ omruiling', be_fr: `Réparation/échange` },
        selected: false
      },
      {
        id: 'wrong-product-delivery',
        name: {
          nl: 'Verkeerde item geleverd',
          be_fr: `Mauvais article livré`
        },
        selected: false
      },
      { id: 'other', name: { nl: 'Anders', be_fr: 'Autre' }, selected: false }
    ],
    carriers: [
      {
        id: 'dhl',
        name: 'DHL',
        imageUrl: 'dhl.jpeg',
        imageUrlMobile: 'dhl-mobile.jpeg'
      },
      {
        id: 'postnl',
        name: 'PostNL',
        imageUrl: 'postnl.jpeg',
        imageUrlMobile: 'postnl-mobile.jpeg'
      },
      {
        id: 'postnl-pickup',
        name: 'PostNL PickUp',
        imageUrl: 'postnl-pickup.jpeg',
        imageUrlMobile: 'postnl-pickup-mobile.jpeg'
      },
      {
        id: 'dynalogic',
        name: 'Dynalogic',
        imageUrl: 'dynalogic.jpeg',
        imageUrlMobile: 'dynalogic-mobile.jpeg'
      },
      {
        id: 'bpost',
        name: 'Bpost',
        imageUrl: 'bpost.jpeg',
        imageUrlMobile: 'bpost-mobile.jpeg'
      },
      {
        id: 'other',
        name: 'Anders',
        imageUrl: 'other.jpeg',
        imageUrlMobile: 'other-mobile.jpeg'
      }
    ]
  },
  {
    id: OTHER,
    navTitle: 'Other',
    name: { nl: 'Other', be_fr: `Autres` },
    description: '',
    reasons: [],
    selected: false
  }
]
