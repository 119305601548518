import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { phoneDown } = breakpoints

export const BaseInputStyles = `
  width: 100%;
  border: none;
  background-color: #f7f7f7;
  border-radius: 2px;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.1875rem;

  &::placeholder {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: rgba(0, 0, 0, 0.4);
  }
`

export const StyledLabel = styled.label`
  font-family: 'SamsungSharpSans', Arial, Sans-Serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.375rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;

  @media ${phoneDown} {
    font-size: 0.75rem;
    line-height: normal;
  }
`

export const StyledInput = styled.input`
  ${BaseInputStyles}
`

export const StyledError = styled.p`
  padding: 0.5rem 1rem;
  color: red;
  font-size: 0.75rem;
`
