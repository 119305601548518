export enum ActionTypes {
  SET_LOADING = 'SET_LOADING',
  SET_STEPS = 'SET_STEPS',
  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',
  SET_ORDER_NO = 'SET_ORDER_NO',
  SET_EMAIL = 'SET_EMAIL',
  SET_DATE = 'SET_DATE',
  SET_COMPLAINTS = 'SET_COMPLAINTS',
  SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED',
  SET_PARTNER_DATA_SHARING = 'SET_PARTNER_DATA_SHARING',
  SET_SUBMIT_FEEDBACK = 'SET_SUBMIT_FEEDBACK',
  SET_ERRORS = 'SET_ERRORS',
  SET_SITE_CODE = 'SET_SITE_CODE'
}
