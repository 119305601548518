import React, { FC, ReactElement, SyntheticEvent } from 'react'

import { termsCopy as copy } from 'assets/copy'
import { microsite } from 'helpers'

import { StyledTerms, StyledBall, StyledInfo } from './Terms.sc'
import useTranslate from 'hooks/useTranslate'

type TermsProps = {
  id: string
  name: string
  checked: boolean
  text: string
  dataOmni: string
  showInfo?: boolean
  onChange: (e: SyntheticEvent) => void
}

const Terms: FC<TermsProps> = ({
  id,
  name,
  checked,
  text,
  dataOmni,
  showInfo = true,
  onChange
}): ReactElement => {
  const { translate: t } = useTranslate()

  return (
    <StyledTerms>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        data-omni-type={microsite}
        data-omni={dataOmni}
      />
      <label htmlFor={id}>
        <StyledBall $checked={checked}>&nbsp;</StyledBall>
        <span>{text}</span>
      </label>
      {showInfo && (
        <StyledInfo>
          <sup>*</sup> {t(copy.required)}
        </StyledInfo>
      )}
    </StyledTerms>
  )
}

export default Terms
