import { styled } from 'styled-components'
import { StyledInput as StyledIp } from 'components/common/Input/Input.sc'

type StyledSVGProps = {
  $isOpen: boolean
}

type StyledOptionTickProps = {
  $checked: boolean
}

export const StyledContainer = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`

export const StyledInputGroup = styled.label`
  position: relative;
  display: block;
`

export const StyledInput = styled(StyledIp)`
  cursor: pointer;
  padding-right: 3rem;
  text-overflow: ellipsis;
`

export const StyledSVG = styled.svg<StyledSVGProps>`
  position: absolute;
  right: 1rem;
  top: 50%;
  cursor: pointer;
  transform: ${({ $isOpen }) =>
    $isOpen
      ? 'translateY(-50%) rotate(-180deg)'
      : 'translateY(-50%) rotate(0deg)'};
  transition: all 0.2s ease;
`

export const StyledOptions = styled.ul`
  box-shadow: rgba(0, 0, 0, 0.13) 0px 4px 10px -2px;
  border-radius: 0 0 0.375rem 0.375rem;
  position: absolute;
  width: 100%;
  max-height: 15.625rem;
  overflow-y: scroll;
`

export const StyledOption = styled.li`
  position: relative;
  z-index: 9999;
`

export const StyledOptionLabel = styled.label`
  display: block;
  background-color: #f7f7f7;
  padding: 0.75rem 0.5rem;
  font-size: 14px;
  cursor: pointer;
  line-height: 19px;
`

export const StyledOptionTick = styled.span<StyledOptionTickProps>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: ${({ $checked }) =>
    $checked ? '1px solid transparent' : '1px solid #000'};
  margin-right: 0.8rem;
  background-color: ${({ $checked }) => ($checked ? '#2189ff' : 'transparent')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('https://images.samsung.com/is/content/samsung/assets/nl/cheil/complaint-form/icons/check.svg');
    background-size: contain;
  }
`

export const StyledOptionInput = styled.input`
  display: none;

  &:hover + ${StyledOptionLabel} {
    background-color: #eee;
    color: rgb(49, 49, 49);
  }
`
