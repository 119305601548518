import { Copy } from 'sebn-library/lib/esm/interfaces'

export const complaintCopy: Copy = {
  reason: {
    nl: 'Reden',
    be_fr: 'Raison'
  },
  description: {
    nl: 'Uitleg, mogelijke verbeteringen',
    be_fr: 'Explications, améliorations possibles'
  },
  other: {
    nl: 'Als geen van de genoemde redenen overeenkomt met uw probleem, kunt u dit hier eenvoudig beschrijven.',
    be_fr: `Si aucune des raisons répertoriées ne correspond à votre problème, décrivez-le simplement ici.`
  },
  carrier: {
    nl: 'Carrier',
    be_fr: `Transporteur`
  }
}
