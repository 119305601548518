import { useContext, useState } from 'react'
import { LocaleString, SiteCode, Country } from '../interfaces'
import { Context } from 'store'

interface TranslateHook {
  translate: (field: LocaleString) => string
  siteCode: SiteCode
  country: Country
}

export default (): TranslateHook => {
  const {
    state: { language }
  } = useContext(Context)

  const [country] = useState<Country>(language === 'nl' ? 'nl' : 'be')

  const translate = (field: LocaleString): string => {
    if (!field) return ''
    return field[language] || field.nl || ''
  }

  return { translate, siteCode: language, country }
}
