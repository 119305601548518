import React, { FC, ReactElement, useContext, useMemo } from 'react'

import { ComplaintId } from 'interfaces'
import { Context } from 'store'
import { optionsString } from 'helpers'
import { complaintCopy as copy } from 'assets/copy'

import { StyledComplaintOverview, StyledRow } from './ComplaintOverview.sc'
import useTranslate from 'hooks/useTranslate'

type ComplaintOverviewProps = {
  complaintId: ComplaintId
}

const ComplaintOverview: FC<ComplaintOverviewProps> = ({
  complaintId
}): ReactElement => {
  const {
    state: { complaints }
  } = useContext(Context)
  const { translate: t } = useTranslate()

  const complaint = useMemo(
    () => complaints.find((c) => c.id === complaintId),
    [complaintId, complaints]
  )

  const selectedReasonsText = useMemo(() => {
    if (!complaint?.reasons.length) return ''
    return optionsString(complaint.reasons, t)
  }, [complaint?.reasons, t])

  if (!complaint) return <></>

  return (
    <StyledComplaintOverview>
      {selectedReasonsText && (
        <StyledRow>
          <h4>{t(copy.reason)}:</h4>
          <p>{selectedReasonsText}</p>
        </StyledRow>
      )}

      {complaint.description && (
        <StyledRow>
          <h4>{t(copy.description)}</h4>
          <p>{complaint.description}</p>
        </StyledRow>
      )}
    </StyledComplaintOverview>
  )
}

export default ComplaintOverview
