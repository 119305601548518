import React, { FC, ReactElement } from 'react'
import { openInNewTab } from 'sebn-library'

import { Button } from 'components'
import { confirmationCopy as copy } from 'assets/copy'
import { project } from 'helpers'
import useTranslate from 'hooks/useTranslate'

import { StyledConfirmation, StyledDetails } from './Confirmation.sc'

const Confirmation: FC = (): ReactElement => {
  const { translate: t, siteCode } = useTranslate()

  return (
    <StyledConfirmation>
      <StyledDetails>
        <h3>{t(copy.thankyou)}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
        >
          <path
            d="M48 93.5C39.001 93.5 30.204 90.8315 22.7216 85.8319C15.2391 80.8323 9.40729 73.7262 5.9635 65.4121C2.51971 57.0981 1.61866 47.9495 3.37429 39.1234C5.12992 30.2973 9.46337 22.19 15.8267 15.8267C22.19 9.46337 30.2973 5.12992 39.1234 3.37429C47.9495 1.61866 57.0981 2.51971 65.4121 5.9635C73.7262 9.40729 80.8323 15.2391 85.8319 22.7216C90.8315 30.204 93.5 39.001 93.5 48C93.4863 60.0631 88.6881 71.6283 80.1582 80.1582C71.6283 88.6881 60.0631 93.4863 48 93.5ZM26.474 39.567L21.525 44.518L42.485 65.45L72.472 35.508L67.526 30.555L42.485 55.555L26.474 39.567Z"
            fill="#2189FF"
          />
        </svg>
        <p>{t(copy.text)}</p>
      </StyledDetails>
      <Button
        variant="underline"
        dataOmni={`${siteCode}:${project}:confirmation:click:${t(copy.goBack)}`}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          openInNewTab(e, t(copy.ctaLink))
        }
      >
        {t(copy.goBack)}
      </Button>
    </StyledConfirmation>
  )
}

export default Confirmation
