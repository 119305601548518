import { ActionTypes } from 'store/actionNames'

export type ButtonVariant = 'black' | 'emphasis' | 'outlined' | 'underline'
export type TranslateHook = (localeString: LocaleString) => string
export type Country = 'nl' | 'be'
export type SiteCode = 'nl' | 'be' | 'be_fr'
export interface LocaleString {
  nl: string
  be?: string
  be_fr?: string
}

export interface StateType {
  language: SiteCode
  isLoading: boolean
  steps: Step[]
  activeStep: Step
  orderNo: string
  email: string
  date: string
  complaints: Complaint[]
  termsAccepted: boolean
  partnerDataSharing: boolean
  submitFeedback: boolean
  errors: Errors
}

export type ActionType =
  | { type: ActionTypes.SET_LOADING; payload: boolean }
  | { type: ActionTypes.SET_STEPS; payload: Step[] }
  | { type: ActionTypes.SET_ACTIVE_STEP; payload: Step }
  | { type: ActionTypes.SET_ORDER_NO; payload: string }
  | { type: ActionTypes.SET_EMAIL; payload: string }
  | { type: ActionTypes.SET_DATE; payload: string }
  | { type: ActionTypes.SET_COMPLAINTS; payload: Complaint[] }
  | { type: ActionTypes.SET_TERMS_ACCEPTED; payload: boolean }
  | { type: ActionTypes.SET_PARTNER_DATA_SHARING; payload: boolean }
  | { type: ActionTypes.SET_SUBMIT_FEEDBACK; payload: boolean }
  | { type: ActionTypes.SET_ERRORS; payload: Errors }
  | { type: ActionTypes.SET_SITE_CODE; payload: SiteCode }

export type Reducer = (state: StateType, action: ActionType) => StateType

export interface Step {
  id: StepId | ComplaintId
  navTitle: string
}

export enum ComplaintId {
  WEBSITE = 'website',
  PRODUCT_INFORMATION = 'product-information',
  ONLINE_PURCHASING = 'online-purchasing',
  DELIVERY_OR_INSTALLATION = 'delivery-or-installation',
  OTHER = 'other'
}

export enum StepId {
  ORDER = 'order',
  DOTS = '...',
  SUBMIT = 'submit'
}

export enum StepperState {
  ACTIVE = 'active',
  DEFAULT = 'default',
  DONE = 'done'
}

export interface Complaint {
  id: ComplaintId
  navTitle: string
  name: LocaleString
  description: string
  reasons: Reason[]
  selected: boolean
  carriers?: Carrier[]
  selectedCarrier?: Carrier
}

export interface Reason {
  id: string
  name: LocaleString
  selected: boolean
}

export interface Carrier {
  id: string
  name: string
  imageUrl: string
  imageUrlMobile: string
}

export interface Option {
  id: string
  name: LocaleString
  selected: boolean
}

export interface Errors {
  orderNo: string
  email: string
}
