import React, {
  FC,
  ReactElement,
  useContext,
  useCallback,
  SyntheticEvent
} from 'react'
import { useWindowSize } from 'sebn-library'

import { InputSelect, InputCheckbox } from 'components'
import { Context } from 'store'
import { setComplaints } from 'store/actions'
import { ComplaintId, Complaint } from 'interfaces'
import { complaintCopy as copy } from 'assets/copy'
import { project } from 'helpers'
import { StyledReasons } from './Reasons.sc'
import useTranslate from 'hooks/useTranslate'

const { DELIVERY_OR_INSTALLATION } = ComplaintId

type ReasonsProps = {
  activeComplaint: Complaint
}

const Reasons: FC<ReasonsProps> = ({ activeComplaint }): ReactElement => {
  const { isMobile } = useWindowSize()
  const {
    state: { activeStep, complaints },
    dispatch
  } = useContext(Context)
  const { translate: t, siteCode } = useTranslate()

  const handleReasonChange = useCallback(
    (e: SyntheticEvent) => {
      if (!activeComplaint) return
      const { name, checked } = e.target as HTMLInputElement

      const updatedComplaints = complaints.map((complaint) => {
        if (complaint.id === activeComplaint.id) {
          const updatedReasons = complaint.reasons.map((reason) => {
            if (reason.id === name) {
              return { ...reason, selected: checked }
            }
            return reason
          })

          return { ...complaint, reasons: updatedReasons }
        }
        return complaint
      })

      dispatch(setComplaints(updatedComplaints))
    },
    [activeComplaint, complaints, dispatch]
  )

  return (
    <>
      <h5>
        {t(copy.reason)} <sup>*</sup>
      </h5>
      {activeStep.id === DELIVERY_OR_INSTALLATION || isMobile ? (
        <InputSelect
          name={`${DELIVERY_OR_INSTALLATION}-reasons`}
          options={activeComplaint.reasons}
          onOptionsChange={handleReasonChange}
          dataOmni={`${siteCode}:${project}:${activeComplaint.navTitle}:reasons`}
        />
      ) : (
        <StyledReasons>
          {activeComplaint.reasons.map((reason) => (
            <InputCheckbox
              key={reason.id}
              name={reason.id}
              id={reason.id}
              checked={reason.selected}
              label={t(reason.name)}
              width="13rem"
              onChange={handleReasonChange}
              dataOmni={`${siteCode}:${project}:${activeComplaint.navTitle}:reasons:click:${t(reason.name)}`}
            />
          ))}
        </StyledReasons>
      )}
    </>
  )
}

export default Reasons
