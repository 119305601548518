import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { tabletDown } = breakpoints

export const StyledNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  margin-top: 3.375rem;

  @media ${tabletDown} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    z-index: 9999;
    background-color: #eee;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
  }
`
