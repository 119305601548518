import { useContext, FC, ReactElement, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Context } from 'store'
import { SiteCode, StepId } from 'interfaces'
import {
  Header,
  Stepper,
  Navigation,
  Order,
  Complaint,
  Overview,
  Confirmation
} from 'components'

import { StyledApp, StyledFlow } from 'App.sc'
import { setSiteCode } from 'store/actions'

const App: FC = (): ReactElement => {
  const {
    state: { activeStep, submitFeedback },
    dispatch
  } = useContext(Context)

  const { lang } = useParams()

  useEffect(() => {
    if (!lang) return

    const tempLang: SiteCode = lang === 'nl' ? 'be' : 'be_fr'
    const language = window.location.href.includes('mysamsung.nl')
      ? 'nl'
      : tempLang

    dispatch(setSiteCode(language))
  }, [dispatch, lang])

  const stepComponent = useMemo(() => {
    if (activeStep.id === StepId.ORDER) return <Order />
    if (activeStep.id === StepId.SUBMIT) return <Overview />
    return <Complaint />
  }, [activeStep.id])

  return (
    <StyledApp>
      <Header />
      {!submitFeedback ? (
        <>
          <Stepper />
          <StyledFlow>{stepComponent}</StyledFlow>
          <Navigation />
        </>
      ) : (
        <Confirmation />
      )}
    </StyledApp>
  )
}

export default App
