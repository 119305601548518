import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { desktopDown, tabletDown } = breakpoints

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;

  @media ${tabletDown} {
    row-gap: 1.5rem;
    margin-bottom: 4rem;
  }
`

export const StyledDetails = styled.div`
  display: flex;
  gap: 1.5rem;

  & > div {
    flex: 2;
  }

  & > div:last-child {
    flex: 1;
  }

  @media ${desktopDown} {
    gap: 1rem;
  }

  @media ${tabletDown} {
    flex-direction: column;
  }
`

export const StyledTopics = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media ${desktopDown} {
    gap: 1rem;
  }
`

export const StyledTerms = styled.div`
  h6 {
    margin-bottom: 1.5rem;
  }
`
