import { StateType, ActionType } from 'interfaces'
import { ActionTypes } from './actionNames'

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case ActionTypes.SET_SITE_CODE:
      return {
        ...state,
        language: action.payload
      }
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case ActionTypes.SET_STEPS:
      return {
        ...state,
        steps: action.payload
      }
    case ActionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      }
    case ActionTypes.SET_ORDER_NO:
      return {
        ...state,
        orderNo: action.payload
      }
    case ActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case ActionTypes.SET_DATE:
      return {
        ...state,
        date: action.payload
      }
    case ActionTypes.SET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload
      }
    case ActionTypes.SET_TERMS_ACCEPTED:
      return {
        ...state,
        termsAccepted: action.payload
      }
    case ActionTypes.SET_PARTNER_DATA_SHARING:
      return {
        ...state,
        partnerDataSharing: action.payload
      }
    case ActionTypes.SET_SUBMIT_FEEDBACK:
      return {
        ...state,
        submitFeedback: action.payload
      }
    case ActionTypes.SET_ERRORS:
      return {
        ...state,
        errors: action.payload
      }

    default:
      return state
  }
}
export default reducer
