import { styled } from 'styled-components'
import { breakpoints } from 'assets/variables'

const { tabletLandscapeDown, tabletDown, phoneDown } = breakpoints

export const StyledApp = styled.div`
  max-width: 73.5rem;
  margin: 4.5rem auto;
  padding: 2.5rem;
  background-color: #eee;
  position: relative;

  @media ${tabletLandscapeDown} {
    padding: 1.75rem;
  }

  @media ${tabletDown} {
    padding: 3rem 1.5rem;
    margin: 0;
  }

  @media ${phoneDown} {
    padding: 2rem 1rem;
    margin: 0 auto;
  }
`

export const StyledFlow = styled.main`
  min-height: 26.875rem;

  @media ${tabletDown} {
    padding-bottom: 5rem;
  }
`
