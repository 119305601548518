import { ActionTypes } from 'store/actionNames'
import {
  Step,
  ActionType,
  Complaint,
  StateType,
  Errors,
  SiteCode
} from 'interfaces'
import { createFeedback } from 'api/complaints'

export const setSiteCode = (siteCode: SiteCode): ActionType => {
  return {
    type: ActionTypes.SET_SITE_CODE,
    payload: siteCode
  }
}

export const setLoading = (isLoading: boolean): ActionType => {
  return {
    type: ActionTypes.SET_LOADING,
    payload: isLoading
  }
}

export const setSteps = (steps: Step[]): ActionType => {
  return {
    type: ActionTypes.SET_STEPS,
    payload: steps
  }
}

export const setActiveStep = (activeStep: Step): ActionType => {
  return {
    type: ActionTypes.SET_ACTIVE_STEP,
    payload: activeStep
  }
}

export const setOrderNo = (orderNo: string): ActionType => {
  return {
    type: ActionTypes.SET_ORDER_NO,
    payload: orderNo
  }
}

export const setEmail = (email: string): ActionType => {
  return {
    type: ActionTypes.SET_EMAIL,
    payload: email
  }
}

export const setDate = (date: string): ActionType => {
  return {
    type: ActionTypes.SET_DATE,
    payload: date
  }
}

export const setComplaints = (complaints: Complaint[]): ActionType => {
  return {
    type: ActionTypes.SET_COMPLAINTS,
    payload: complaints
  }
}

export const setTermsAccepted = (termsAccepted: boolean): ActionType => {
  return {
    type: ActionTypes.SET_TERMS_ACCEPTED,
    payload: termsAccepted
  }
}

export const setPartnerDataSharing = (dataSharing: boolean): ActionType => {
  return {
    type: ActionTypes.SET_PARTNER_DATA_SHARING,
    payload: dataSharing
  }
}

export const submitFeedback = async (state: StateType): Promise<ActionType> => {
  const res = await createFeedback(state)

  return {
    type: ActionTypes.SET_SUBMIT_FEEDBACK,
    payload: res.data.acknowledged || false
  }
}

export const setErrors = (errors: Errors): ActionType => {
  return {
    type: ActionTypes.SET_ERRORS,
    payload: errors
  }
}
