import React, {
  FC,
  ReactElement,
  useContext,
  useCallback,
  SyntheticEvent
} from 'react'

import { Context } from 'store'
import { setComplaints } from 'store/actions'
import { ComplaintId, Complaint } from 'interfaces'
import { complaintCopy as copy } from 'assets/copy'
import useTranslate from 'hooks/useTranslate'

import { StyledExplanation, StyledTextarea } from './Explanation.sc'

type ExplanationProps = {
  activeComplaint: Complaint
}

const Explanation: FC<ExplanationProps> = ({
  activeComplaint
}): ReactElement => {
  const {
    state: { activeStep, complaints },
    dispatch
  } = useContext(Context)
  const { translate: t } = useTranslate()

  const handleDescriptionChange = useCallback(
    (e: SyntheticEvent) => {
      if (!activeComplaint) return
      const { value } = e.target as HTMLTextAreaElement

      const updatedComplaints = complaints.map((c) => {
        if (c.id === activeComplaint.id) {
          return { ...c, description: value }
        }
        return c
      })

      dispatch(setComplaints(updatedComplaints))
    },
    [activeComplaint, complaints, dispatch]
  )

  return (
    <StyledExplanation>
      <h5>
        {activeStep.id === ComplaintId.OTHER
          ? t(copy.other)
          : t(copy.description)}
      </h5>

      <StyledTextarea
        name={activeComplaint.id}
        id={activeComplaint.id}
        placeholder={t(copy.description)}
        value={activeComplaint.description}
        maxLength={1000}
        onChange={handleDescriptionChange}
      ></StyledTextarea>
    </StyledExplanation>
  )
}

export default Explanation
